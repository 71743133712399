import { all, fork } from 'redux-saga/effects'
import multiUpload from './multiUploadRedux/saga'
import singleUpload from './uploadRedux/saga'
import ordersSaga from '../features/orders/redux/saga'
import deliveryMenSaga from '../features/dashboard/redux/saga.map'
import partnerOrdersSaga from '../features/PartnersOrders/redux/saga'
import deliveryMenForTrackingSaga from '../features/PartnersOrders/redux/deliveryMen.saga'
import allpartnerOrdersSaga from '../features/AllPartnersOrders/redux/saga'
import AllPartnerdeliveryMenForTrackingSaga from '../features/AllPartnersOrders/redux/deliveryMen.saga'
import currnetRideSaga from '../features/driver/redux/saga'
import settingReducer from '../features/PartnersOrders/redux/setting.saga'


export default function* rootSaga() {
    yield all([
        fork(singleUpload),
        fork(multiUpload),
        fork(ordersSaga),
        fork(deliveryMenSaga),
        fork(partnerOrdersSaga),
        fork(deliveryMenForTrackingSaga),
        fork(allpartnerOrdersSaga),
        fork(AllPartnerdeliveryMenForTrackingSaga),
        fork(currnetRideSaga),
        fork(settingReducer),
    ]);
}